import React from 'react';

import MasterPage from './MasterPage';
import { usePostApi, useFetchApi } from '../libs/useDataApiV2';
import SamGrid from '../libs/dashboard/SamGridV3'
import { useTokens } from '../libs/SamState';
import IconButton from '../libs/IconButton';

import { FormFieldRecord, FormFieldType } from '../interfaces/lib-api-interfaces';

import app from '../appData';

const SearchMaint: React.FC = () => {
    const { isPostLoading, post } = usePostApi();
    const { fetch } = useFetchApi();
    const { getToken } = useTokens();
    const [searchText, setSearchText] = React.useState<string>();
    const [dataSource, setDataSource] = React.useState<Record<string, any>[]>();
    const searchRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const searchApiUrl = "/api/wsSearch";
    const createIndexApiUrl = "/api/wsCreateIndex/H";

    const columnDefs: FormFieldRecord[] = [
        { type: FormFieldType.text, name: "item_no", label: "Item #" },
       { type: FormFieldType.text, name: "caption", label: "Caption" }
    ];

    React.useEffect(() => {
        if (searchText) {
            post(searchApiUrl, { division: app.division, searchText }, result => setDataSource(result), error => alert(error), getToken()!.token);
        }
    }, [searchText]);

    const keyPress = (e: React.KeyboardEvent) => {
        if (e.which === 13) {
            setSearchText(searchRef.current.value);
        }
    }

    const customFormatter = (dataRow: Record<string, any>, colName: string, value: any): string | null => {
        if (colName === "item_no") {
            return value[0] + "-" + value.slice(1);
        } else {
            return null;
        }
    }
    const reindex = () => {
        fetch(createIndexApiUrl, getToken()!.token, result => alert(result), error => alert(error));
    }

    return (
        <MasterPage>
            <h2>Search Index Maintenance</h2>
            <IconButton style={{ display: "block", marginBottom: "8px", marginLeft: "auto", marginRight: "auto" }} onClick={reindex} caption="Reindex" icon="fas fa-sync" />
            <input ref={searchRef} size={50} placeholder="Enter search text here" onKeyPress={keyPress} />
            {isPostLoading() && <i className="fa fa-spinner fa-spin spinner48"></i>}
            {dataSource &&
                <SamGrid styles={{ marginTop: "8px" }} dataSource={dataSource} columnDefs={columnDefs} fixedCols={0} allowEditing={false} customFormatter={customFormatter} />
            }
        </MasterPage>

    )
}
export default SearchMaint;