import { MenuItemData, DivisionEnum } from './interfaces/lib-api-interfaces';

const isDashboard = true;

export const menuItems = [
    new MenuItemData("Home", "/home"),
    new MenuItemData("Search maintenance", "/search-maint"),
];

export const division = DivisionEnum.htm;
export const domain = "dashboard.heartthemoment";
export const serverIp = "https://" + domain + ".com";
export const logoUrl = "dashboard_logo.jpg";
const targetDomain = "heartthemoment.com";

export const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : serverIp) + "/graphics";

export const themes = {
    defaultFonts: "Verdana,sans-serif",
    sansFonts: "Verdana,sans-serif",
    h1FontFamily: "Courier",
    navbarFont: null,
    foreColor: "black",
    backColor50: "#f5b1a1",
    backColor25: "#f5d4c3",
    backColor10: "#fff9f7",
    fontSize: 14
};

export const initialState = {
};

export default {
    isDashboard,
    menuItems,
    division,
    domain,
    serverIp,
    targetDomain,
    logoUrl,
    productGraphicsBaseUrl,
    themes
}

