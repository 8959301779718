import React from 'react';
import styled from 'styled-components';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { useTokens } from '../libs/SamState';
import SamForm from '../libs/forms/SamFormV4';
import useFormMgr from '../libs/forms/useFormMgr';
import { StyledErrorText } from '../libs/libSupport';
import { usePostApi, genericApiError } from '../libs/useDataApiV2';

import { FormFieldRecord, TokenRecord } from '../interfaces/lib-api-interfaces';

import app from '../appData';

const LoginContainer = styled.div`
    text-align: center;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
`
const Login: React.FC<RouteComponentProps> = (props) => {
    const { setToken, getToken } = useTokens();
    const { post, isPostLoading } = usePostApi();
    const forms = useFormMgr();

    const [errorMsg, setErrorMsg] = React.useState<string>();

    if (getToken()) {
        return (
            <Redirect to="/home" />
        )
    }

    let apiUrl = ("/api/dashboardLogin/" + app.domain);

    const successfulPost = (result: TokenRecord, status: number | undefined) => {
        // api returns token as encrypted string
        //   console.log("success:", result)
        if (status === 201) {
            setErrorMsg("User name and password not found");
        } else {
            setToken(result, false);       // do not persist login
            props.history.push("/home");
        }
    }
    const failedPost = (message: string) => {
  //      console.log("failed: " + message)
        if (message) {
            setErrorMsg(message);
        } else {
            setErrorMsg(genericApiError);
        }
    }
    const handleSubmit = (inputs: Record<string, any> | null) => {
        inputs!.division = app.division;
        console.log("posting to " + apiUrl)
        post(apiUrl, inputs, successfulPost, failedPost);
    }
    const formData: FormFieldRecord[] = [
        { name: "userName", label: "User name", validator: { required: true, maxLength: 50 } },
        { name: "password", label: "Password", validator: { isPassword: true, required: true, maxLength: 50 } },
    ];
    return (
        <LoginContainer>
            <h2>Sign in</h2>
            {isPostLoading(apiUrl) && <i className="fa fa-spinner fa-spin spinner48"></i>}
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            <SamForm id="login" forms={forms} fields={formData} submitButtons={[{ id: "submit", caption: "Sign in", icon: "fas fa-check" }]}
                handleSubmit={handleSubmit} />
        </LoginContainer>
    );
}

export default Login;