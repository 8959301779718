import React from 'react';
import { Switch, Route } from "react-router-dom";

// routes
import Login from './components/Login';
import Home from './components/Home';
import SearchMaint from './components/SearchMaint';

export const Routes = () => {

    return (
        <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/home" component={Home} />
            <Route path="/search-maint" component={SearchMaint} />
        </Switch>
    );
}
export default Routes;
