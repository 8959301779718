import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import NavBar from '../libs/NavBarV3';
import { useTokens, useSessionStore } from '../libs/SamState';
import { formatImageUrl } from '../libs/libSupport';

import app from '../appData';

const StyledMasterContainer = styled.div<{ defaultFonts: string; h1FontFamily: string; foreColor: string }>`
    text-align: center;
    font-family: ${props => props.defaultFonts};
    color: ${props => props.foreColor};
    a:link {
        text-decoration: none; color: ${props => props.foreColor}
    }
    a:visited {
        text-decoration: none; color: ${props => props.foreColor}
    }
    a:hover {
        font-style: italic; text-decoration: underline; color: ${props => props.foreColor}
    }
    h1 {
        font-family: ${props => props.h1FontFamily};
        text-align: center;
        font-size: 32px;
        margin-top: 24px;
        margin-bottom: 4px;
        font-weight: bold;
    }
    h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
`
const ContentAndCart = styled.div`
    display: flex;
    flex-direction: row;
`
const StyledContentContainer = styled.div`
    width: 100%;
`
const StyledCartContainer = styled.div`
    flex-basis: 350px;
`
const MasterPage: React.FC<PropsWithChildren<{}>> = (props) => {
    const { getToken } = useTokens();
    const { setSessionStore } = useSessionStore();
    const token = getToken();

    if (!token) {
        return (
            <Redirect to="/login" />
        )
    }

    const handleRefresh = () => {
        if (token) {
            setSessionStore("token", token);
        }
    }
    window.addEventListener('beforeunload', handleRefresh);

    return (
        <StyledMasterContainer defaultFonts={app.themes.defaultFonts} h1FontFamily={app.themes.h1FontFamily ? app.themes.h1FontFamily : app.themes.defaultFonts}
            foreColor={app.themes.foreColor}>
                <img src={formatImageUrl(app.logoUrl, { graphicsSubfolder: "other" })} />
            <NavBar menuItems={app.menuItems} height={42} fontSize={14} fontWeight="normal" />
                        {props.children}
        </StyledMasterContainer>
    );
}
export default MasterPage;
