import React from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TS_Editor } from 'tinymce';


import IconButton from '../IconButton';
import { formatImageOrVideoSrcWithFile, replaceAll } from '../libSupport';
import ContextMenu, { ContextMenuInfo } from '../SamContextMenu';
import SamModal from '../SamModal';
import ImageListHandler, { AttributesPosition, ImageAttributesEditOptions, ImageUploadOptions } from './ImageListHandler';

import { GraphicFloatType, ImageFileOptions, ImageRecord, InfoContentTypeEnum, RowState, TextOrImageRecord } from '../../interfaces/lib-api-interfaces';

import app from '../../appData';

// this is dependent on "npm i @tinymce/tinymce-react"

/* ways to access edited value:
    1) default: component displays "Save" and "Cancel" buttons, caller passes handleExit callback which receives value
    2) do not pass handleExit callback, do pass onChange; onChange is passed text along with optional id field (useful if more than one editor on page)
*/
/* props: 
    id: passed back with onChange, needed for multiple editors on page
    html: string (html)
    removePTags: bool -- pass true to convert newlines to P tags on entry, and back to newlines on exit
    textWidth: int -- text area width
    textHeight: int -- to enable scrolling if necessary
    onChange(html, id)
    handleExit -- callback, edited text passed, or null to cancel
*/
const MasterContainer = styled.div<{ width: string; height: number; foreColor: string }>`
    width: ${props => props.width};
    height: ${props => props.height}px;
    color: ${props => props.foreColor};
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    padding: 8px;
`
const ToolbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    max-width: 590px;
    margin: 4px auto 8px auto;
`
const ButtonContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
    button {
        margin-left: 8px;
    }
`
const UrlInputContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
`
const VideoInputHelp = styled.p`
    margin: 0;
    font-size: 12px;
    font-style: italic;
`
export const removeAllPTags = (text: string): string => {
    return replaceAll(replaceAll(text, "<p>", ''), "</p>", '');
}

// use the following when passing onChange rather than handleExit
export const convertNewlinesToParagraphTags = (text: string): string => {
    return ("<p>" + text.split('\n').join("</p><p>") + "</p>");
}
export const convertParagraphTagsToNewlines = (html: string): string => {
    if (html) {
        return html.split("</p><p>").join('\n').split("<p>").join('').split("</p>").join('');
    } else {
        return html;
    }
}
export const stripPreAndPostPTags = (html: string): string => {
    if (html.startsWith("<p>")) {
        html = html.substring(3);
    }
    if (html.endsWith("</p>")) {
        html = html.substring(0, html.length - 4);
    }
    return html;
}
// when pasting text it seems spaces at end of line become hard
export const removeHardSpaces = (text: string): string => {
    return replaceAll(replaceAll(text, "&nbsp;", " "), "  ", " ");
}
// combine text and images prior to editing
export const assembleTextAndImages = (content: TextOrImageRecord[], graphicsSubfolder?: string): string => {
    let text = '';
    content.forEach(record => {
        if (record.content_type === InfoContentTypeEnum.image) {
            text += buildImageHtml(record.image!, graphicsSubfolder);
        } else if (record.content_type === InfoContentTypeEnum.newParagraph) {
            if (!text.endsWith("</div>")) {
                text += "</p>";     // close preceding graf if not an image block
            }
            text += "<p>";
        } else if (record.content_type === InfoContentTypeEnum.text) {
            text += record.text;
        } else if (record.content_type === InfoContentTypeEnum.link) {
            text += buildLinkHtml(record);
        }
    });
    console.log("ASSEMBLE:", content); console.log("=====>", text)
    return text;
}
export const disassembleTextAndImages = (text: string): TextOrImageRecord[] => {
 //   console.log("DISASSEMBLING:", text)
    const records: TextOrImageRecord[] = [];
    const pAndI = splitParagraphsAndImages(text);
 //   console.log("paragraphs:", pAndI)
    pAndI.forEach(pAndIText => {
        parseTextAndImages(records, pAndIText);
    });
    console.log("DISASSEMBLE:", text); console.log("======>", records);
    return records;
}
// STEP 1: remove <p> tags, separate our image blocks and return array of paragraphs with embedded links
// place image divs interspersed in their own entries
const splitParagraphsAndImages = (text: string): string[] => {
    //   let count = 0;
    const paragraphs: string[] = [];
    let posn = 0;
    while (posn < text.length) {
        let imagePosn = text.indexOf('<div id="blog!image!', posn);
   //     console.log("SPLITPARAGRAPHS..."); console.log("imagePosn=" + imagePosn + ", " + text.substring(imagePosn, imagePosn + 10))
        if (imagePosn === -1) {
            splitParagraphs(text.substring(posn), paragraphs);      // no more images; split the rest of the text up into pagraphs and we're done
            return paragraphs;
        }
        if (imagePosn !== posn) {
            // we got an image but there is text before it
    //        console.log("SPLITTING: " + text.substring(posn, imagePosn))
            splitParagraphs(text.substring(posn, imagePosn), paragraphs);     // push the text preceding the image
            posn = imagePosn;
        }
        // now find end of image and push to its own record
        const endPosn = text.indexOf("</div", posn) + 6;
        paragraphs.push(text.substring(posn, endPosn));     // push the image block
        posn = endPosn;
    }
    return paragraphs;
}
// STEP 2: records are either full paragraph text with embedded links, or image blocks
// parse into content records with links, etc. broken out
const parseTextAndImages = (records: TextOrImageRecord[], text: string) => {
    if (text.startsWith("<div")) {
        // this is an image
        records.push({ content_type: InfoContentTypeEnum.image, image: parseImageFromHtml(text) });
    } else {
        // this is a paragraph without p tags
        records.push({ content_type: InfoContentTypeEnum.newParagraph });
        let posn = 0;
        while (posn < text.length) {
            let endPosn = text.indexOf('<a', posn);
            if (endPosn === -1) {
                endPosn = text.length;
            }
            records.push({ content_type: InfoContentTypeEnum.text, text: text.substring(posn, endPosn) });
            posn = endPosn;
            if (posn < text.length) {
                // we found a link so parse and add it to sections
                endPosn = text.indexOf("</a>", posn) + 4;
                const [linkText, url] = parseLink(text.substring(posn, endPosn));
                records.push({ content_type: InfoContentTypeEnum.link, text: linkText, url });
                posn = endPosn;
            }
        }
    }
}
// STEP 3: images have been removed so just split into pargaghs without the p tags
const splitParagraphs = (text: string, paragraphs: string[]) => {
    let posn = 0;
    while (posn < text.length) {
        posn = text.indexOf("<p>", posn);
        //    console.log("posn=" + posn + ": " + text.substring(posn, posn + 10))
        if (posn === -1) {
            break;      // no more paragraphs
        }
        let endPosn = text.indexOf("</p>", posn);
        if (endPosn === -1) {
            endPosn = text.length;
        }
        // console.log("endPosn=" + endPosn + ": " + text.substring(endPosn, endPosn + 10))
        // console.log("pushing " + text.substring(posn + 3, endPosn))
        paragraphs.push(text.substring(posn + 3, endPosn));
        posn = endPosn;
        //     count++;
        // if (count === 2) {
        //     break;
        // }
    }
    return paragraphs;
}
// note that if link is to a video, caption will be postpended in urlencoded format
const parseLink = (link: string): [string, string] => {     // linkText, url
    let posn = link.indexOf("href=") + 6;   // past quote
    let endPosn = link.indexOf('"', posn);
    if (endPosn === -1) {
        endPosn = link.indexOf("'", posn);
    }
    let url = link.substring(posn, endPosn);
    posn = link.indexOf(">", endPosn) + 1;       // 1st char of text
    endPosn = link.indexOf("</a>", posn);   // last char of text
    //   console.log("link=" + link + ", at text posn=" + link.substring(posn, posn + 10) + ", at endPosn=" + link.substring(endPosn, endPosn + 10))
    const linkText = link.substring(posn, endPosn);
    // tinymce seems to replace leading slash with ../
    if (url.startsWith("..")) {
        url = url.substring(2);
    }
    if (url.startsWith('/')) {
        url = url.substring(1);
    }
  //  console.log("url=" + url)
  console.log("parseLink: " + link + " returns " + url)
  return [linkText, url];
}
const buildLinkHtml = (record: TextOrImageRecord): string => {
    return '<a href="' + record.url + '">' + record.text + "</a>";
}

// next 2 are inverse of each other -- keep together
const buildImageHtml = (image: ImageRecord, graphicsSubfolder?: string): string => {
    const filename = replaceAll(image.filename!, '.', '-');
    const float = graphicFloatTypeToString(image.float);
    return '<div id="blog!image!' + filename + '" style="text-align: center"><img src="' + formatImageOrVideoSrcWithFile(image, { graphicsSubfolder })
        + '" style="width:' + image.size + 'px;float:' + float + ';height:auto" />' + (image.caption ? ('<p id=blog!caption!' + filename
            + ' style="margin-top: 2px" class="mceNonEditable">' + image.caption + '</p>') : '') + '</div>';
}
// example of html:
//      <div id="blog!image!fern-tiara-jpg" style="text-align: center;"><img style="width: 600px; height: auto;" 
//      src="https://artthisdesign.com/graphics/other/fern-tiara.jpg" /></div>
// with caption: '<div id="blog!image!...<img src=".../graphics/blog/aboutus-1.jpg">...class="mceNonEditable">Caption here</p>'
const parseImageFromHtml = (html: string): ImageRecord => {
    const image: ImageRecord = {};
    let posn = html.indexOf("src=") + 5;
    const endQuotePosn = html.indexOf('"', posn);
    const fullFilename = html.substring(posn, endQuotePosn);
    console.log({ posn, endQuotePosn, fullFilename })
    posn = fullFilename.lastIndexOf('/') + 1;
    image.filename = fullFilename.substring(posn);
    const classPosn = html.indexOf('"mceNonEditable"', endQuotePosn);
    if (classPosn !== -1) {
        const startCaptionPosn = html.indexOf(">", classPosn) + 1;
        const endCaptionPosn = html.indexOf("</p>", startCaptionPosn);
        image.caption = html.substring(startCaptionPosn, endCaptionPosn);
    }
    //   console.log({ classPosn, endCaptionPosn, text: html.substring(classPosn, endCaptionPosn)});
    const sizePosn = html.indexOf("width:") + 6;
    const endSizePosn = html.indexOf('px', sizePosn);
    image.size = parseInt(html.substring(sizePosn, endSizePosn));
    const floatPosn = html.indexOf("float:");
    if (floatPosn == -1) {
        image.float = GraphicFloatType.none;
    } else {
        const endFloatPosn = html.indexOf(';', floatPosn);
        const float = html.substring(floatPosn + 6, endFloatPosn).trim();
        image.float = stringToGraphicFloatType(float);
    }
    return image;
}
const graphicFloatTypeToString = (floatType?: GraphicFloatType): string => {
    let float: string;
    if (floatType === GraphicFloatType.right) {
        float = "right";
    } else if (floatType === GraphicFloatType.left) {
        float = "left";
    } else {
        float = "none";
    }
    return float;
}
const stringToGraphicFloatType = (float: string): GraphicFloatType => {
    if (float === "right") {
        return GraphicFloatType.right;
    } else if (float === "left") {
        return GraphicFloatType.left;
    }
    return GraphicFloatType.none;
}

interface EmbeddedImageRecord {
    image: ImageRecord;            // valid unless inserting image
    // following are required if editing image; not given if inserting image
    bookmark?: any;                  // tinymce bookmark to selected image
    cursorStartPosn?: number;        // position of "<div id=blog!image!filenameId"
    cursorEndPosn?: number;          // position of </div> after image or caption; not needed if inserting image
}

interface TextEditorProps {
    id?: string;
    title?: string;
    html?: string;      // can be omitted if setEditor is used
    removePTags?: boolean;
    textWidth?: number;     // default to 100%
    textHeight?: number;
    allowVideos?: boolean;
    imageUploadOptions?: ImageUploadOptions;            // images are disallowed if this is not give; next 2 also required
    imageEditOptions?: ImageAttributesEditOptions;      // required if allowImages true
    imageFileOptions?: ImageFileOptions;                // ditto
    setEditor?: (editor: TS_Editor) => void;      // called when editor initialized so parent has access to content
    handleExit?: (html: string | null) => void;
}
const TextEditor: React.FC<TextEditorProps> = props => {
    const [editor, setEditor] = React.useState<TS_Editor>();
    const [showURLInput, setShowURLInput] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState<ContextMenuInfo>();
    const [imageButtonLocation, setImageButtonLocation] = React.useState<{ x: number; y: number }>();
    const [choosingNewImage, setChoosingNewImage] = React.useState(false);
    const [finishingNewImage, setFinishingNewImage] = React.useState(false);
    const [editingImage, setEditingImage] = React.useState<EmbeddedImageRecord>();

    const urlRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
    const captionRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const height = props.textHeight ? props.textHeight : 300;

    //    console.log("TextEditor:", props)

    React.useEffect(() => {
        if (showURLInput) {
            urlRef.current.focus();
        }
    }, [showURLInput]);
    React.useLayoutEffect(() => {
        const elem = document.getElementById("image");
        if (elem) {
            const rect = elem.getBoundingClientRect();
            if (!imageButtonLocation || imageButtonLocation.x !== rect.x || imageButtonLocation.y !== rect.y) {
                setImageButtonLocation({ x: rect.x, y: rect.y });
            }
        }
    });

    const handleOk = () => {
        const html = editor!.getContent();
        props.handleExit!(props.removePTags ? convertParagraphTagsToNewlines(html) : html);
    }
    const handleCancel = () => {
        props.handleExit!(null);
    }

    const applyFormat = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const target = e.target as HTMLButtonElement;
        if (target.id === "LINK") {
            setShowURLInput(true);
        } else if (target.id === "image") {
            setChoosingNewImage(true);         // display ImageListHandler for user to choose an image to insert
        } else {
            editor!.execCommand(target.id);
        }
    }

    const onLinkInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!urlRef.current.value.startsWith("video://") && e.key === "Enter") {
            e.preventDefault();
            confirmLinkDlg();
        }
    }
    const confirmLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {    // called from button; else called from pressing ENTER on input field
            e.preventDefault();
        }
        confirmLinkDlg();
    }
    // video links are inserted into the html as: <a href="video://youtubeId/caption" (with caption urlencoded)
    // other links are either <a href="https:..." or <a href="xyz..." (where xyz is an internal link)
    const confirmLinkDlg = () => {
        if (!urlRef.current.value) {
            alert("Please enter a url");
        } else {
            let url = urlRef.current.value;
            if (url.startsWith("video://")) {
                url += "/" + (captionRef.current.value ? encodeURIComponent(captionRef.current.value) : '');
            }
            editor!.execCommand('mceInsertLink', false, url);
            setShowURLInput(false);
            console.log(editor?.getContent())
        }
    }
    // returns the embedded image record for selected image; return null if no image or caption selected
    const loadEmbeddedImageFromSelection = (): EmbeddedImageRecord | null => {
        const selection = editor!.selection.getSel();
        if (selection && selection.focusNode) {
            const focusNode = selection.focusNode as HTMLElement;
            const previousSibling = focusNode.previousSibling as HTMLElement;
            let text: string | null = null;
            let key: string = '';
            if (focusNode.outerHTML && focusNode.outerHTML.includes("blog!image!")) {
                // image is selected
                key = "image";
                text = focusNode.outerHTML;
                //        console.log("focusNode.outerHTML=" + focusNode.outerHTML)
            } else if (focusNode.previousSibling && previousSibling.outerHTML && previousSibling.outerHTML.includes("blog!caption!")) {
                // caption is selected
                key = "caption";
                text = (focusNode.previousSibling as HTMLElement).outerHTML;
            }
            if (text) {
                key = "blog!" + key + "!";
                const startPosn = text.indexOf(key) + key.length;
                const endPosn = text.indexOf('"', startPosn);
                const filenameId = text.substring(startPosn, endPosn);
                console.log("filenameId=" + filenameId)
                // search the entire document for the embdedded image and caption as created by buildImageHtml above
                const html = editor!.getContent();
                //   console.log(html);
                const cursorStartPosn = html.indexOf('<div id="blog!image!' + filenameId + '"');
                const cursorEndPosn = html.indexOf("</div>", cursorStartPosn) + 6;
                const bookmark = editor!.selection.getBookmark();
                const image = parseImageFromHtml(html.substring(cursorStartPosn, cursorEndPosn));
                console.log({ image, bookmark, cursorStartPosn, cursorEndPosn })
                console.log("SELECTED TEXT: " + html.substring(cursorStartPosn, cursorEndPosn))
                return { image, bookmark, cursorStartPosn, cursorEndPosn };
            }
        }
        return null;
    }

    // delete embedded image and keep cursor where it is
    const deleteImage = (embeddedImage: EmbeddedImageRecord) => {
        const html = editor!.getContent();
        const newHtml = html.substring(0, embeddedImage.cursorStartPosn) + html.substring(embeddedImage.cursorEndPosn!);
        editor!.setContent(newHtml);
        editor!.selection.moveToBookmark(embeddedImage.bookmark);
    }
    // replace embedded image keeping cursor where it is
    const replaceImage = (embeddedImage: EmbeddedImageRecord, newImage: string) => {
        const html = editor!.getContent();
        const newHtml = html.substring(0, embeddedImage.cursorStartPosn) + newImage + html.substring(embeddedImage.cursorEndPosn!);
        editor!.setContent(newHtml);
        editor!.selection.moveToBookmark(embeddedImage.bookmark);
    }

    /* following is called when ImageListHandler closes, returns with new image or edited existing image or finished new image
        to add an image: 
            1) user clicked "Images" button 
            2) choosingNewImage is set, ImageListHandler shown 
            3) finishingNewImage is loaded, ImageListHandler shown again 
            4) image is inserted
        to replace an image: 
            1) user double clicks the image 
            2) editingImage loaded, ImageListHandler shown 
            3) user adds image, Handler returns with 2 images 
            4) images[1] replaces existing image
        to edit existing image: 
            1) user double clicks the image 
            2) editingImage loaded, ImageListHandler shown 
            3) edited image replaced existing image
        to delete image:
            user selected image and presses delete; following function never called 
            OR user edits image and chooses delete from there; rowState returned as deleted
    */
    const editImageSubmitted = (images: ImageRecord[]) => {
        const image = images.length === 2 ? images[1] : images[0];
        const isDeleted = image.rowState && (image.rowState & RowState.deleted) !== 0;
        if (finishingNewImage) {
            console.log("finishingNewImage:", image)
            // a new image has been chosen and the caption/size have been set; images[0] has the finished image to insert
            if (!isDeleted) {
                // user could have deleted while finishing in which case this is a no-op
                //    image.size = props.defaultImageSize;
                //    image.rowState = RowState.unchanged;
                const html = buildImageHtml(image, props.imageFileOptions && props.imageFileOptions.graphicsSubfolder);
                //   console.log(html)
                // if (editingImage) {
                //     // user wants to replace existing image with new one so delete old one first
                //     deleteImage(editingImage);
                // }
                editor!.execCommand('mceInsertContent', false, html);
                setFinishingNewImage(false);
                setEditingImage(undefined);
            }
        } else if (choosingNewImage) {
            // user just chose a new image and now needs to finish setting caption and size
            image.size = props.imageFileOptions!.size;
            image.dimension = props.imageFileOptions!.dimension;
            setEditingImage({ image });       // go back and add caption etc. to new image
            setFinishingNewImage(true);
            setChoosingNewImage(false);
        } else if (images.length == 2) {
            // we are replacing current image with new one
            // first new one needs finishing
            setFinishingNewImage(true);     // will be inserted when we come back from ImageListHandler; edtingImage should also be set here so image will be replaced
            image.size = props.imageFileOptions!.size;
            image.dimension = props.imageFileOptions!.dimension;
            setEditingImage({ image });       // go back and add caption etc. to new image
        } else if (isDeleted) {
            deleteImage(editingImage!);
            setEditingImage(undefined);
        } else {
            // caption or size has been changed so delete existing and insert new
            const html = buildImageHtml(image, props.imageFileOptions && props.imageFileOptions.graphicsSubfolder);
            replaceImage(editingImage!, html);
            setEditingImage(undefined);
        }
    }
    const urlInputKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setShowURLInput(false);
        }
    }
    const editorDoubleClick = (e: any) => {
        const image = loadEmbeddedImageFromSelection();
        if (image) {
            setEditingImage(image);
        }
    }
    const editorKeyDown = (e: any) => {
        if (e.key === "Delete") {
            const image = loadEmbeddedImageFromSelection();
            if (image) {
                deleteImage(image);
                return false;
            }
        }
    }
    const handleEditorChange = (text: string, editor: TS_Editor) => {
        //        console.log("editorChange: " + text)
    }

    interface InitObject {
        setup: (editor: TS_Editor) => void;
        menubar: boolean;
        height: number;
        plugins: string[];
        toolbar: boolean;
    }
    const initObject: InitObject = {
        setup: (editor) => {
            setEditor(editor);
            if (props.setEditor) {
                props.setEditor(editor);
            }
        },
        menubar: false,
        height: height,
        plugins: [
            'advlist autolink lists charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount noneditable'
        ],
        toolbar: false,
    }
    return (
        <MasterContainer width={props.textWidth ? props.textWidth + "px" : "100%"} height={height} foreColor={app.themes.foreColor}>
            {props.title && <h2>{props.title}</h2>}
            {props.handleExit &&
                <ButtonContainer>
                    <button onClick={handleOk}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                </ButtonContainer>}
            <Toolbar allowImages={!!props.imageUploadOptions} handleClick={applyFormat} />
            {showURLInput &&
                <React.Fragment>
                    <UrlInputContainer onKeyUp={urlInputKeyUp}>
                        <label>Type or paste the url:</label>
                        <input ref={urlRef} type="text" size={50} defaultValue='' onKeyDown={onLinkInputKeyDown} />
                        <IconButton marginLeft={4} onClick={confirmLink} caption="Confirm" />
                    </UrlInputContainer>
                    {props.allowVideos &&
                        <React.Fragment>
                            <VideoInputHelp>To create a video link, use format "video://YOUTUBE ID"</VideoInputHelp>
                            <label>Caption (for video only):</label>
                            <input ref={captionRef} type="text" size={150} defaultValue='' />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
            <Editor
                initialValue={props.html ? (props.removePTags ? convertNewlinesToParagraphTags(props.html) : props.html) : ''}
                init={initObject}
                onEditorChange={handleEditorChange}
                onKeyDown={editorKeyDown}
                onDblclick={editorDoubleClick}
            />
            {contextMenu && <ContextMenu info={contextMenu} closePopup={() => setContextMenu(undefined)} />}
            {editingImage && <SamModal onClose={() => setEditingImage(undefined)} component={ImageListHandler as React.FC}
                componentProps={{
                    images: [editingImage.image], imageBoxWidth: 400,
                    fileOptions: props.imageFileOptions,
                    direction: "row", size: 400, attributesPosition: AttributesPosition.bottom,
                    editOptions: props.imageEditOptions,
                    uploadOptions: finishingNewImage ? undefined : props.imageUploadOptions,
                    onChange: editImageSubmitted
                }} />
            }
            {choosingNewImage && <SamModal onClose={() => setChoosingNewImage(false)} component={ImageListHandler as React.FC}
                componentProps={{
                    images: [], imageBoxWidth: 400, attributesPosition: AttributesPosition.bottom,
                    fileOptions: props.imageFileOptions,
                    editOptions: props.imageEditOptions, direction: "row", size: 400,
                    uploadOptions: props.imageUploadOptions,
                    onChange: editImageSubmitted
                }} />
            }
        </MasterContainer>
    );
}

interface ToolbarProps {
    allowImages?: boolean;
    handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const Toolbar: React.FC<ToolbarProps> = (props) => {
    const backColor = app.themes.backColor10;
    const buttonStyle = { backgroundColor: backColor, padding: "0 8px 0 8px", margin: 0 }
    return (
        <ToolbarContainer>
            <IconButton id="bold" style={buttonStyle} icon="fa fa-bold" caption="Bold" onClick={props.handleClick} />
            <IconButton id="italic" style={buttonStyle} icon="fa fa-italic" caption="Italic" onClick={props.handleClick} />
            <IconButton id="underline" style={buttonStyle} icon="fa fa-underline" caption="Underline" onClick={props.handleClick} />
            <IconButton id="LINK" style={buttonStyle} icon="fa fa-link" caption="Link" onClick={props.handleClick} />
            <IconButton id="removeformat" style={buttonStyle} icon="fa fa-ban" caption="Clean" onClick={props.handleClick} />
            {props.allowImages &&
                <IconButton id="image" style={buttonStyle} icon="fas fa-image" caption="Image" onClick={props.handleClick} />
            }
        </ToolbarContainer>
    )
}

export default TextEditor;

