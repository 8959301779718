const getInfoContent = "/api/getInfoContent/" // add page url OR section code to get index of section
const setInfoContent = "/api/setInfoContent";
const dashboardLogin = "/api/dashboardLogin/fernsgarden";
const search = "/api/wsSearch";
const createIndex = "/api/fgCreateIndex";

const uploadImage = "/api/uploadImage";

export default {
    getInfoContent,
    setInfoContent,
    dashboardLogin,
    search,
    createIndex,

    uploadImage,

}